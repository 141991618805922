<template lang="pug">
div
  vue-element-loading(:active='isActive', spinner='ring', color='#428BCA')
  div(v-if='layout')
    label.col-sm-3.control-label.no-padding-right(:for='name')  {{ name }}
    .col-sm-5
      multiselect(ref='multi',
        v-model='select',
        :options='dados',
        :placeholder='optionText ? optionText : "Todos"',
        selectLabel='Selecione',
        selectedLabel='Selecionado',
        deselect-label='Remover',
        :multiple='true',
        :track-by='titulo',
        :label='titulo',
        :searchable='true')
        template(slot='selection', slot-scope='{ values, search, isOpen }')
          span.multiselect__single(v-if='values.length && !isOpen') {{ values.length }} selecionado(s)
  div(v-else)
    label(:for='name') {{ name }}
    multiselect(ref='multi',
      v-model='select',
      :options='dados',
      :placeholder='optionText ? optionText : "Todos"',
      selectLabel='Selecione',
      selectedLabel='Selecionado',
      deselect-label='Remover',
      :multiple='true',
      :track-by='titulo',
      :label='titulo',
      :searchable='true')
      template(slot='selection', slot-scope='{ values, search, isOpen }')
        span.multiselect__single(v-if='values.length && !isOpen') {{ values.length }} selecionado(s)
</template>

<script>
import Multiselect from 'vue-multiselect'
import VueElementLoading from 'vue-element-loading'
import req from '@/components/request'

export default {
  components: {
    VueElementLoading,
    Multiselect
  },
  props: ['value', 'name', 'url', 'params', 'id', 'titulo', 'layout', 'optionText', 'required', 'resetar'],
  data () {
    return {
      dados: [],
      isActive: false,
      select: []
    }
  },
  watch: {
    select () {
      this.updateSelect()
    },
    resetar () {
      this.handleReset()
    }
  },
  methods: {
    handleReset () {
      this.select = []
    },
    updateSelect () {
      this.$emit('input', this.select ? this.select.map(i => i[this.id] ? i[this.id] : i) : [])
      sessionStorage.setItem(`_${this.url}_select`, this.select ? JSON.stringify(this.select.map(i => i[this.id] ? i[this.id] : i)) : '')
    },
    getRequest () {
      if (sessionStorage.getItem(`_${this.url}`)) {
        this.dados = JSON.parse(sessionStorage.getItem(`_${this.url}`))
        this.select = JSON.parse(sessionStorage.getItem(`_${this.url}_select`))
      } else {
        this.isActive = true
        req(this.url, 'get', this.params).then(resp => {
          this.dados = resp
          sessionStorage.setItem(`_${this.url}`, JSON.stringify(resp))
          this.isActive = false
          this.select = this.value ? this.value.map(v => resp.filter(i => i[this.id] === v)[0]) : []
          sessionStorage.setItem(`_${this.url}_select`, JSON.stringify(this.select))
        })
      }
    }
  },
  mounted () {
    this.getRequest()
  }
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style lang="stylus">
.multiselect, .multiselect__input, .multiselect__single {
  font-size: 14px;
}

.multiselect__select {
    height: 34px;
    padding: 7px 8px;
}

.multiselect__tags {
  min-height: 34px;
  padding: 0px 40px 0 8px;
  border: 1px solid #d5d5d5;
  border-radius: 0
}

.multiselect__placeholder {
  color: #858585;
  margin-bottom: 0px;
  padding-top: 5px;
}

.multiselect__single {
  padding-left: 5px;
  margin-bottom: 0;
  margin-top: 5px;
}

.multiselect__input, .multiselect__single {
  margin-bottom: 0;
}

.multiselect--above .multiselect__content-wrapper {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.multiselect__content-wrapper {
  z-index: 2;
}

.multiselect__input
  border 0 !important
</style>
