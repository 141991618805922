import Vue from 'vue'

export default {
  methods: {
    ACTIONTEMPLATE (e) {
      var vm = this
      return {
        template: Vue.component('temp', {
          template: `<div>
            <div style='float: left;' v-if='ACTION.cc'>
              <a :href='urlEditar(templateArgs.clienteid)'
                @click.prevent='buttonClickEditar(templateArgs.clienteid)'
                style="width: 40px;" class='btn btn-warning btn-sm' title='Editar Cliente'>
                <i class='fa fa-address-book-o'></i>
              </a>
            </div>
            <div style='float: left;' v-if='ACTION.ca'>
              <a :href='urlEditar(templateArgs.clienteid, "/agenda")'
                @click.prevent='buttonClickEditar(templateArgs.clienteid, "/agenda")'
                style="width: 40px;" class='btn btn-primary btn-sm' title='Nova Agenda'>
                <i class='fa fa-calendar-check-o'></i>
              </a>
            </div>
            <div style='float: left;' v-if='ACTION.cv'>
              <a :href='urlEditar(templateArgs.clienteid, "/vendas")'
                @click.prevent='buttonClickEditar(templateArgs.clienteid, "/vendas")'
                style="width: 40px;" class='btn btn-success btn-sm' title='Nova Venda'>
                <i class='fa fa-cart-plus'></i>
              </a>
            </div>
            </div>`,
          data () {
            return {
              templateArgs: {},
              ACTION: vm.ACTION
            }
          },
          methods: {
            urlEditar (clienteid, pag = '') {
              return `/sistema/clientes/${clienteid}${pag}`
            },
            buttonClickEditar (clienteid, pag = '') {
              vm.$router.push(`/sistema/clientes/${clienteid}${pag}`)
            }
          }
        }),
        templateArgs: e
      }
    }
  }
}
