<template lang="pug">
div
  .page-header
    .row
      .col-md-6
        h1 Vendas
      .col-md-5.col-md-offset-1
          .text-right
            router-link(to='/sistema/clientes/novo').btn.btn-success Novo Cliente
  .row
    .col-md-3
      label Clientes:
      input.form-control(v-model='filtro.cliente', placeholder='Clientes')
    .col-md-3
      select-form(v-model='filtro.tipo_emprestimo', :value='filtro.tipo_emprestimo',name='Tipo de Emprestimo', url='/tipo-emprestimo', id='id', titulo='nome')
    .col-md-3
      select-multi-form(v-model='filtro.status', :value='filtro.status', name='Status', url='/status/vendas', id='id', titulo='nome', :resetar='resetar')
    .col-md-3
      select-form(v-model='filtro.orgao', :value='filtro.orgao', name='Orgão', url='/orgao', id='id', titulo='nome')
  .row
    .col-md-3
      label Periodo: (
        a(href='#', @click.prevent='setDataAtual') Atual
        | )
      br
      date-range-picker(:opens='opens', @update='updateValues', :locale-data='locale', :startDate='startDate', :endDate='endDate', :minDate='minDate')
    .col-md-3
      label(for='tipo') Tipo Data
      select.form-control(v-model='filtro.datatipo')
        option(:value='d.id', v-for='d in datatipo') {{d.nome}}
    .col-md-3
      select-form(v-model='filtro.usuario', :value='filtro.usuario', name='Usuário', url='/usuarios', id='id', titulo='name')
    .col-md-3
      br
      button.btn.btn-primary(@click.prevent='getLimparFiltro') Limpar
  br
  grid(:url='urlDataSource', :source='source', :columns='columns', :grid='grid', ref='datasource1')
</template>

<script>
import Vue from 'vue'
import {mapGetters, mapActions} from 'vuex'
import DateRangePicker from '@/components/form/DateRangePicker/DateRangePicker'
import moment from 'moment'
import numeral from 'numeral'
import 'numeral/locales/pt-br'
import Grid from '@/components/Grid'
import JSZip from 'jszip'
import SelectForm from '@/components/form/SelectForm'
import SelectMultiForm from '@/components/form/SelectMultiForm'
import Permission from '@/mixins/Permission'
import Template from '@/mixins/Template'

// eslint-disable-next-line no-undef
window.JSZip = JSZip
window.moment = moment
numeral.locale('pt-BR')

export default {
  components: {
    Grid,
    SelectForm,
    DateRangePicker,
    SelectMultiForm
  },
  mixins: [Template, Permission],
  computed: {
    ...mapGetters([
      'user'
    ]),
    source () {
      return [{
        filter: this.filter,
        serverFiltering: true,
        schemaParse: this.parse,
        pageSize: 50,
        serverPaging: true,
        schemaData: 'data',
        schemaTotal: 'total',
        schemaModel: this.modelo,
        serverSorting: true
      }]
    },
    filter () {
      return {
        logic: 'and',
        filters: [
          { field: 'cod', operator: 'contains', value: this.filtro.cod },
          { field: 'nome', operator: 'contains', value: this.filtro.cliente },
          { field: 'dt_instalacao', operator: 'contains', value: this.filtro.datavenda, value2: this.filtro.datatipo },
          { field: 'periodo', operator: 'contains', value: this.filtro.dataini, value2: this.filtro.datafim, value3: this.filtro.datatipo },
          { field: 'status_id', operator: 'contains', value: this.filtro.status },
          { field: 'usuario_id', operator: 'contains', value: this.filtro.usuario },
          { field: 'instalacao_id', operator: 'contains', value: this.filtro.instalador },
          { field: 'tipo_emprestimo', operator: 'contains', value: this.filtro.tipo_emprestimo },
          { field: 'orgao', operator: 'contains', value: this.filtro.orgao },
          { field: 'periodo_id', operator: 'contains', value: this.filtro.periodo_id }
        ]
      }
    }
  },
  watch: {
    'filtro.cod' () {
      this.setFiltro()
    },
    'filtro.cliente' () {
      this.setFiltro()
    },
    'filtro.datavenda' () {
      this.setFiltro()
    },
    'filtro.status' () {
      this.setFiltro()
    },
    'filtro.usuario' () {
      this.setFiltro()
    },
    'filtro.instalador' () {
      this.setFiltro()
    },
    'filtro.datatipo' () {
      this.setFiltro()
    },
    'filtro.tipo_emprestimo' () {
      this.setFiltro()
    },
    'filtro.orgao' () {
      this.setFiltro()
    },
    'filtro.dataini' () {
      this.setFiltro()
    },
    'filtro.datafim' () {
      this.setFiltro()
    },
    'filtro.periodo_id' () {
      this.setFiltro()
    }
  },
  data () {
    return {
      urlDataSource: `${this.$url}/vendas`,
      resetar: false,
      startDate: moment().startOf('day').startOf('month').format('YYYY-MM-DD'),
      endDate: moment(),
      minDate: moment('2017-02-01').startOf('month'),
      opens: 'ltr',
      locale: {
        direction: 'ltr',
        format: 'DD/MM/YYYY',
        separator: ' - ',
        applyLabel: 'Aplicar',
        cancelLabel: 'Cancelar',
        weekLabel: 'W',
        customRangeLabel: 'Custom Range',
        daysOfWeek: moment.weekdaysMin(),
        monthNames: moment.monthsShort(),
        firstDay: 1,
        open: true
      },
      tipo_emprestimo: null,
      clientes: [],
      filtro: {
        cod: null,
        cliente: null,
        datavenda: null,
        status: null,
        usuario: null,
        instalador: null,
        datatipo: 'dt_venda',
        tipo_emprestimo: null,
        orgao: null,
        dataini: moment().startOf('day').startOf('month').format('YYYY-MM-DD'),
        datafim: moment().format('YYYY-MM-DD'),
        periodo_id: ''
      },
      range: '',
      modelo: {
        fields: {
          datacad: { type: 'date' },
          dataatual: { type: 'date' },
          dt_venda:  { type: 'date' },
          dt_instalacao: { type: 'date' },
          previsao_inst: { type: 'date' }
        }
      },
      datatipo: [
        { id: 'dt_venda', nome: 'Data Venda' },
        { id: 'datacad', nome: 'Data de Cadastro' },
        { id: 'dataatual', nome: 'Ultima Atualização' },
      ],
      columns: [
        { field: 'id', title: 'Cod', width: 75, locked: true, lockable: false },
        { field: 'clientes_nome', title: 'Cliente', width: 350, locked: true, lockable: false, template: this.itemTemplate },
        { field: 'status_nome', title: 'Status', width: 180, template: this.statusTemplate },
        { field: 'usuario_nomeatual', title: 'Usuário', width: 300 },
        { field: 'dt_venda', title: 'Data Venda', width: 120, format: '{0:dd/MM/yyyy}' },
        { field: 'dataatual', title: 'Ultima Atualização', width: 120, format: '{0:dd/MM/yyyy HH:mm}' },
        { field: 'datacad', title: 'Cadastro', width: 120, format: '{0:dd/MM/yyyy HH:mm}' },
        { field: 'tipo_emp_nome', title: 'Tipo de Emprestimo', width: 120 },
        { field: 'orgao_nome', title: 'Orgão', width: 120 },
        { field: 'telefone1', title: 'Telefone 1', width: 120, height: 82 },
        { field: 'telefone2', title: 'Telefone 2', width: 120 },
        { field: 'telefone3', title: 'Telefone 3', width: 120 },
        { field: 'end_rua', title: 'Rua', width: 120 },
        { field: 'end_numero', title: 'Numero', width: 120 },
        { field: 'end_compl', title: 'Complemento', width: 120 },
        { field: 'end_bairro', title: 'Bairro', width: 120 },
        { field: 'end_cep', title: 'CEP', width: 120 },
        { field: 'end_cidade', title: 'Cidade', width: 120 },
        { field: 'end_estado', title: 'Estado', width: 120 },
        { field: 'valor_financ', title: 'Vlr Financiamento', width: 120 },
        { field: 'parc_valor', title: 'Vlr Parcelamento', width: 120 },
        { field: 'parc_qtd_parcelas', title: 'Qtd Parcelas', width: 120 },
        { field: 'parc_qtd_taxa', title: 'Taxa', width: 120 },
        { template: this.ACTIONTEMPLATE, title: 'Ações', width: 145, locked: true },
        { template: this.commandTemplate, title: 'Editar', width: 105, height: 82, locked: true }
      ],
      grid: [{
        height: 500,
        toolbar: [{ name: 'excel', text: 'Excel', iconClass: 'k-icon k-i-excel' }],
        excelFileName: `vox-${Math.random().toString(36).substring(7)}.xlsx`,
        excelFilterable: true,
        excelAllPages: true,
        navigatable: true,
        columnMenu: true,
        sortable: true,
        pageable: true,
        pageablePageSizes: [15, 50, 75, 100],
        pageableAlwaysVisible: true
      }]
    }
  },
  methods: {
    ...mapActions([
      'getLogsTotal'
    ]),
    parse (d) {
      d.data.map(i => {
        i.clientes_nome = i.clientes_nome.toUpperCase()
        i.fotos_nome = i.fotos_nome ? i.fotos_nome.toUpperCase() : ''
        i.instalador_nome = i.instalador_nome ? i.instalador_nome.toUpperCase() : ''
        i.periodo_nome = i.periodo_nome ? i.periodo_nome.toUpperCase() : ''
        i.sinal_nome = i.sinal_nome ? i.sinal_nome.toUpperCase() : ''
        i.tipo_emprestimo = i.tipo_emprestimo ? i.tipo_emprestimo.toUpperCase() : ''
        i.usuario_nome = i.usuario_nome ? i.usuario_nome.toUpperCase() : ''
        i.usuario_nomeatual = i.usuario_nomeatual ? i.usuario_nomeatual.toUpperCase() : ''
        i.telefone1 = i.telefone1 ? i.telefone1.replace(/[^\d]+/g, '') : ''
        i.telefone2 = i.telefone2 ? i.telefone2.replace(/[^\d]+/g, '') : ''
        i.telefone3 = i.telefone3 ? i.telefone3.replace(/[^\d]+/g, '') : ''
        return i
      })
      return d
    },
    updateValues (values) {
      this.filtro.dataini = moment(values.startDate).format('YYYY-MM-DD')
      this.filtro.datafim = moment(values.endDate).format('YYYY-MM-DD')
    },
    handleClientes () {
      this.$axios('/clientes').then(resp => {
        this.clientes = resp.data
      })
    },
    commandTemplate (e) {
      var vm = this
      return {
        template: Vue.component('temp', {
          template: `<div>
            <div style='float: left;'  v-if='ACTION.ev'>
              <a :href='urlEditarVisualizar(templateArgs.clientes_id, templateArgs.id)'
                @click.prevent='buttonClickEditarVisualizar(templateArgs.clientes_id, templateArgs.id)'
                style="width: 40px;" class='btn btn-grey btn-sm btn-editar' title='Editar'>
                <i class='fa fa-search'></i>
              </a>
            </div>
            <div style='float: left;'  v-if='ACTION.ev'>
              <a :href='urlEditar(templateArgs.clientes_id, templateArgs.id)'
                @click.prevent='buttonClickEditar(templateArgs.clientes_id, templateArgs.id)'
                style="width: 40px;" class='btn btn-light btn-sm btn-editar' title='Editar'>
                <i class='fa fa-pencil-square-o'></i>
              </a>
            </div>
            <div style='float: left;' v-if='ACTION.av' @click.prevent='apagar(templateArgs.id)'>
              <a href='#' style="width: 40px;" class='btn btn-danger btn-sm btn-apagar' title='Remover'>
                <i class='fa fa-times'></i>
              </a>
            </div>
            </div>`,
          data () {
            return {
              templateArgs: {},
              ACTION: vm.ACTION
            }
          },
          methods: {
            urlEditar (clientesId, id) {
              return `/sistema/vendas/${id}/${clientesId}`
            },
            urlEditarVisualizar  (clientesId, id) {
              return `/sistema/vendas/${id}/${clientesId}/visualizar`
            },
            buttonClickEditar (clientesId, id) {
              vm.$router.push(`/sistema/vendas/${id}/${clientesId}`)
            },
            buttonClickEditarVisualizar(clientesId, id) {
              window.open(`/sistema/vendas/${id}/${clientesId}/visualizar`, '_blank')
              //vm.$router.push(`/sistema/vendas/${id}/${clientesId}/visualizar`)
            },
            urlEditarContrato (clientesId, id) {
              return `/imprimir/contrato/${id}`
            },
            buttonClickEditarContrato (clientesId, id) {
              window.open(`/imprimir/contrato/${id}`, '_blank')
            },
            urlEditarRecibo (clientesId, id) {
              return `/imprimir/recibo/${id}`
            },
            buttonClickEditarRecibo (clientesId, id) {
              window.open(`/imprimir/recibo/${id}`, '_blank')
            },
            apagar (id) {
              this.$swal.fire({
                title: 'Você tem certeza?',
                text: 'Você não poderá reverter isso!',
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                cancelButtonText: 'Cancelar',
                confirmButtonText: 'Sim, exclua!'
              }).then((result) => {
                if (result.value) {
                  this.$axios.delete(`/vendas/${id}`, {
                    data: {
                      USER: vm.user.id
                    }
                  }).then(() => {
                    vm.getLogsTotal(vm.user.id)
                    this.$swal.fire(
                      'Excluído!',
                      'Essa venda foi excluída.',
                      'success'
                    )
                    vm.$children[6].$refs.datasource1.kendoDataSource.read()
                  })
                }
              })
            }
          }
        }),
        templateArgs: e
      }
    },
    itemTemplate (e) {
      var vm = this
      return {
        template: Vue.component('temp', {
          template: `
                <div style='height: 112px;'>
                  <a :href='url(templateArgs.clientes_id)' style='padding: 5px;display: block;color: #337ab7;' @click.prevent="buttonClick(templateArgs.clientes_id)">
                    {{ templateArgs.clientes_nome }}
                  </a>
                </div>
            `,
          data () {
            return {
              templateArgs: {}
            }
          },
          methods: {
            url (id) {
              return `/sistema/clientes/${id}`
            },
            buttonClick (id) {
              vm.$router.push(`/sistema/clientes/${id}`)
            }
          }
        }),
        templateArgs: e
      }
    },
    statusTemplate (e) {
      return {
        template: Vue.component('temp', {
          template: `
          <div style='height: 112px;display: flex;flex-direction: row;align-items: center;'>
            <span style='display: block;padding: 5px;margin: 18px;width: 100%;' :style='[{ "background-color" : templateArgs.status_cor }, {color : getColorByBgColor(templateArgs.status_cor)}]'>{{ templateArgs.status_nome }}</span>
          </div>`,
          data () {
            return {
              templateArgs: {}
            }
          },
          methods: {
            hexToRgb (hex) {
              let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
              return result ? {
                r: parseInt(result[1], 16),
                g: parseInt(result[2], 16),
                b: parseInt(result[3], 16)
              } : null
            },
            getColorByBgColor (hexcolor) {
              let r = this.hexToRgb(hexcolor).r
              let g = this.hexToRgb(hexcolor).g
              let b = this.hexToRgb(hexcolor).b
              let yiq = ((r * 299) + (g * 587) + (b * 114)) / 1000
              return (yiq >= 128) ? 'black' : 'white'
            }
          }
        }),
        templateArgs: e
      }
    },
    getLimparFiltro () {
      let filtro = {
        cod: null,
        cliente: null,
        datavenda: null,
        status: null,
        usuario: null,
        instalador: null,
        datatipo: 'datacad',
        tipo_emprestimo: null,
        orgao: null,
        dataini: moment().startOf('day').startOf('month').format('YYYY-MM-DD'),
        datafim: moment().format('YYYY-MM-DD')
      }
      localStorage.setItem('_filtroVendas', JSON.stringify(filtro))
      sessionStorage.removeItem('_/status/vendas_select')
      this.startDate = moment(filtro.dataini)
      this.endDate = moment(filtro.datafim)
      this.filtro = filtro
      this.resetar = !this.resetar
    },
    setDataAtual () {
      let getFiltro = JSON.parse(localStorage.getItem('_filtroVendas'))
      let filtro = {
        cod: getFiltro.cod,
        cliente: getFiltro.cliente,
        datavenda: getFiltro.datavenda,
        status: getFiltro.status,
        usuario: getFiltro.usuario,
        instalador: getFiltro.instalador,
        datatipo: getFiltro.datatipo,
        tipo_emprestimo: getFiltro.tipo_emprestimo,
        orgao: getFiltro.orgao,
        dataini: moment().startOf('day').startOf('month').format('YYYY-MM-DD'),
        datafim: moment().format('YYYY-MM-DD')
      }
      localStorage.setItem('_filtroVendas', JSON.stringify(filtro))
      this.startDate = moment(filtro.dataini)
      this.endDate = moment(filtro.datafim)
      this.filtro = filtro
    },
    setFiltro () {
      localStorage.setItem('_filtroVendas', JSON.stringify(this.filtro))
    }
  },
  mounted () {
    let filtro = localStorage.getItem('_filtroVendas') ? JSON.parse(localStorage.getItem('_filtroVendas')) : null
    if (filtro) {
      this.startDate = moment(filtro.dataini)
      this.endDate = moment(filtro.datafim)
      this.filtro = filtro
    }
  }
}
</script>

<style lang="stylus" scoped>
.actions {
  float: left
}
</style>

<style lang="stylus">
.k-grid-content-locked {
  height: 367px !important;
}
</style>
